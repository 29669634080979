import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
import "./SchedulingCalendar.css";

import moment from "moment-timezone";
import _ from "underscore";
import {
  chevronBack,
  chevronForward,
  navigateCircle,
  timeOutline,
} from "ionicons/icons";
import SchedulingCalendar from "./SchedulingCalendar";
import SchedulingHours from "../SchedulingHours/SchedulingHours";
import SchedulingForm from "../SchedulingForm/SchedulingForm";
import StringUtils from "../../../lib/StringUtils";
import API from "../../../lib/API";
import StorageUtils from "../../../lib/StorageUtils";
import AppointmentInformation from "../AppointmentInformation/AppointmentInformation";
import plnSymbol from "../../../assets/img/logo/pln_symbol.svg";
import Constants from "../../../lib/Constants";
import Event from "../../../lib/Event";
import PubSub from "../../../lib/PubSub";
import { Capacitor } from "@capacitor/core";
import SchedulingCalendarV2 from "./SchedulingCalendarV2";

const InlineSchedulingModal: React.FC<{
  store?: any;
  appointmentType?: any;
  existingAppointment?: any;
  forceType?: boolean;
  forceStack?: boolean;
  fields?: any;
  buttonText?: any;
  requiredFields?: any;
  lead?: any;
  customer?: any;
  presentingElement?: any;
  onSave?: any;
  onCancel?: any;
  title?: any;
  clearOnClose?: any;
  nativeMode?: any;
  id?: any;
  event?: any;
}> = ({
  appointmentType = null,
  existingAppointment = null,
  store = null,
  forceType = false,
  forceStack = false,
  fields = ["name", "email", "phone", "goal", "notes"],
  buttonText = "Schedule",
  requiredFields = [],
  lead = null,
  customer = null,
  presentingElement = null,
  onSave = null,
  onCancel = "",
  title = "Schedule Consultation",
  clearOnClose = false,
  nativeMode = false,
  id = null,
  event = "",
}) => {
  const modalRef = useRef<HTMLIonModalElement>(null);
  const [consultationDate, setConsultationDate] = useState<any>(null);
  const [consultationDateTime, setConsultationDateTime] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [appointment, setAppointment] = useState<any>(null);
  const [error, setErrorMessage] = useState("");
  const [formValues, setFormValues] = useState<any>({
    name: "",
    email: "",
    phone: "",
    notes: "",
    goal: "",
  });

  useEffect(() => {
    PubSub.subscribe(
      event ? event : Event.MODAL.SCHEDULING_MODAL,
      (evt: any) => {
        console.log(evt);

        if (evt?.type == "close") {
          return modalRef?.current?.dismiss();
        }

        if (id && evt?.id == id) {
          modalRef?.current?.present();
        }
      }
    );
  }, []);

  useEffect(() => {
    if (!modalRef?.current) {
      return;
    }

    modalRef?.current?.onDidDismiss().then(() => {
      if (clearOnClose) {
        setAppointment(null);
        setConsultationDate(null);
        setConsultationDateTime(null);
      }
    });
  }, [modalRef?.current]);

  const setError = (msg: any) => {
    if (!msg) {
      setErrorMessage(msg);

      return;
    }

    setErrorMessage(msg);

    setTimeout(() => {
      setError("");
    }, 5000);
  };

  const schedule = async () => {
    setSubmitting(true);

    let phone = formValues?.phone
      ? StringUtils.formatPhoneForServer(formValues?.phone)
      : "";

    let email = formValues?.email ? formValues?.email : "";
    let name = formValues?.name ? formValues?.name : "";

    if (!email && lead?.email) {
      email = lead?.email;
    }

    if (!email && customer?.email) {
      email = customer?.email;
    }

    if (!name && lead?.name) {
      name = lead?.name;
    }

    if (!name && customer?.name) {
      name = customer?.name;
    }

    if (!phone && lead?.phone) {
      phone = lead?.phone;
    }

    if (!phone && customer?.phone) {
      phone = customer?.phone;
    }

    let payload: any = {
      leadID: lead?._id ? lead?._id : null,
      customerID: customer?._id ? customer?._id : null,
      appointmentType: appointmentType?.APPOINTMENT_TYPE,
      dateTime: consultationDateTime,
      name: name,
      email: email,
      phone: phone,
      notes: formValues?.notes ? formValues?.notes : "",
      goal: formValues?.goal ? formValues?.goal : "",
      forceAppointmentType: forceType ? true : false,
      captchaToken:
        "Uot9isGEnMoyGRFRzyaorxGUR3reqddSUVRv2Acn3tsGuHmITP8Cka72rTipj60ogaW",
    };

    API.scheduleAppointment(store?._id, payload)
      .then(
        async (data: any) => {
          // console.log(data?.data?.data);

          setAppointment(data?.data?.data?.appointment);

          /*await StorageUtils.set(
            "checkout_appointment",
            data?.data?.data?.appointment
          );*/

          if (typeof onSave == "function") {
            onSave(data?.data?.data?.appointment);
          }
        },
        (err) => {
          setError(
            `Unable to schedule: ${
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "Unknown error occurred."
            } Please try again`
          );
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const hasAllFields = () => {
    let hasAll = true;

    if (!requiredFields?.length) {
      return true;
    }

    requiredFields?.forEach((field: any) => {
      if (!formValues[field]) {
        hasAll = false;
      }
    });

    return hasAll;
  };

  return (
    <>
      <IonModal
        ref={modalRef}
        canDismiss={true}
        mode={
          !nativeMode ? "ios" : Capacitor.getPlatform() == "ios" ? "ios" : "md"
        }
        className="modal-inlinescheduler"
      >
        <IonHeader>
          <IonToolbar
            mode={
              !nativeMode
                ? "ios"
                : Capacitor.getPlatform() == "ios"
                ? "ios"
                : "md"
            }
            className={
              nativeMode ? "modal-toolbar-native" : "box-modal-toolbar-web"
            }
            style={{ borderBottom: "none" }}
          >
            {!appointment && (
              <IonButtons slot="start">
                <IonButton
                  color="dark"
                  onClick={() => {
                    modalRef?.current?.dismiss();

                    setConsultationDate(null);

                    setConsultationDateTime(null);
                  }}
                >
                  Cancel
                </IonButton>
              </IonButtons>
            )}
            <IonTitle>{title ? title : "Schedule Consultation"}</IonTitle>

            <IonButtons slot="end">
              <IonButton
                color={appointment ? "dark" : "primary"}
                onClick={() => {
                  if (appointment) {
                    modalRef?.current?.dismiss();

                    setAppointment(null);

                    setConsultationDate(null);

                    setConsultationDateTime(null);

                    return;
                  }

                  schedule();
                }}
                disabled={
                  !hasAllFields() ||
                  !consultationDate ||
                  !consultationDateTime ||
                  submitting
                }
              >
                {submitting ? (
                  <IonSpinner name="dots"></IonSpinner>
                ) : (
                  <>
                    {appointment ? (
                      <>Done</>
                    ) : (
                      <>{buttonText ? buttonText : "Schedule"}</>
                    )}
                  </>
                )}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {appointment ? (
            <>
              <div className="ion-padding">
                <AppointmentInformation
                  appointment={appointment}
                  onChange={(appointment: any) => {
                    setAppointment(appointment);
                  }}
                  forceStack={forceStack}
                  onCancel={(appointment: any) => {
                    if (typeof onCancel == "function") {
                      onCancel(appointment);
                    }
                  }}
                ></AppointmentInformation>
              </div>
            </>
          ) : (
            <>
              <div className="ion-padding">
                <div className="mb-4">
                  <div
                    className="border p-[0.75rem] mb-4 mt-1"
                    style={{ borderRadius: 6 }}
                  >
                    <div className="flex gap-x-3 items-center">
                      <div className="">
                        <IonIcon
                          icon={navigateCircle}
                          className="text-orange-500 h-8 w-8 relative top-[1px]"
                        />
                      </div>
                      <div size-xs="" className="grow">
                        <h5 className="text-dark m-0 font-weight-bold">
                          {store?.name
                            ?.replace("Project LeanNation", "PLN")
                            .trim()}
                        </h5>
                        <p className="m-0" style={{ fontSize: 14 }}>
                          {store?.location?.address?.formattedAddress}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <h2 className="font-weight-bold text-xl mb-[0.25rem]">
                    Schedule Your {appointmentType.NAME}
                  </h2>
                  <p className="text-base">{appointmentType.DESCRIPTION}</p>
                  <div className="flex justify-center">
                    <div
                      className="mt-2 px-2 rounded-md bg-gray-25 border-gray-100 border-[1px] text-gray-900"
                      style={{ fontSize: 14 }}
                    >
                      <span
                        style={{ position: "relative", top: 3 }}
                        className="pr-1"
                      >
                        <IonIcon icon={timeOutline}></IonIcon>
                      </span>

                      {appointmentType.DURATION_READABLE}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ion-padding">
                <SchedulingCalendarV2
                  store={store}
                  appointmentType={appointmentType}
                  value={consultationDateTime}
                  onDateSelected={(date: any) => {
                    console.log("Date Selected", date);

                    setConsultationDate(date);
                    setConsultationDateTime(date);
                  }}
                ></SchedulingCalendarV2>
              </div>
              <div>
                <SchedulingForm
                  appointmentType={appointmentType}
                  title="Additional Information"
                  fields={fields}
                  requiredFields={requiredFields}
                  onChange={(vals: any) => {
                    setFormValues(vals);

                    // console.log(vals);
                  }}
                  value={formValues}
                ></SchedulingForm>
              </div>
            </>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default InlineSchedulingModal;
