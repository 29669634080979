import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonSegment,
  IonSegmentButton,
  withIonLifeCycle,
} from "@ionic/react";
import { useParams } from "react-router";
import NavbarV2 from "../../../../components/Checkout/Navbar/NavbarV2";
import ConversionForm from "../../../../components/ConversionForm/ConversionForm";
import "./Plans.css";

import background from "../../../../assets/img/backgrounds/bg_meals_superlight_lg.jpg";
import backgroundSM from "../../../../assets/img/backgrounds/bg_meals_superlight_sm.jpg";
import personalized from "../../../../assets/img/icons/personalized.svg";
import coaching from "../../../../assets/img/icons/coaching.svg";
import inbody from "../../../../assets/img/icons/inbody.svg";
import store from "../../../../assets/img/icons/store.svg";

import React, { useMemo } from "react";
import MealTopicSlider from "../../../../components/ConversionForm/MealTopicSlider";
import TestimonialSlider from "../../../../components/ConversionForm/TestimonialSlider";
import FAQQuestions from "../../../../components/FAQQuestions";
import Footer from "../../../../components/Checkout/Footer";
import { arrowForwardOutline, navigateCircleOutline } from "ionicons/icons";

import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _, { select } from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import StringUtils from "../../../../lib/StringUtils";
import SegmentUtils, {
  SegmentIdentify,
  SegmentProductCategories,
} from "../../../../lib/SegmentUtils";
import SEOHeaders from "../../../../components/SEOHeaders";
import PostHogUtils from "../../../../lib/PostHogUtils";
import MealOptionCard from "../../../../components/MealOptionCard";
import MealOptionCardNoSelection from "../../../../components/MealOptionCardNoSelection";
import MealDetailModal from "../../../../components/MealDetailModal";
import NumberUtils from "../../../../lib/NumberUtils";

class PlansV2 extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    mode: "selectstore",
    coupon: null,
    loadingStore: true,
    planTypes: [
      {
        _id: "613bdb37164e9569b55f38d0",
        name: "Lifestyle",
        description:
          "The foundation of our lifestyle plan is consuming whole foods that are perfectly portioned to help you crush your health goals.  You’ll love the way it feels to eat nutrient-rich, gluten-free meals.",
        calorieMin: 400,
        calorieMax: 500,
        Created: "2021-09-29T17:57:47.764Z",
        LastUpdated: "2021-09-29T17:57:47.764Z",
        groupCode: "L",
        _idString: "613bdb37164e9569b55f38d0",
      },
      {
        _id: "613bddd9970e86ceef4dd8fc",
        name: "Athlete",
        description:
          "Do you do more, lift more, or have a heartier appetite? We got you covered. Our Athlete meals are 1.5 times the size of a Lifestyle meal to provide even more fuel and recovery from high-intensity workouts.",
        calorieMin: 600,
        calorieMax: 800,
        Created: "2021-09-29T17:57:48.603Z",
        LastUpdated: "2021-09-29T17:57:48.603Z",
        groupCode: "A",
        _idString: "613bddd9970e86ceef4dd8fc",
      },
    ],
    activePlanType: "613bdb37164e9569b55f38d0",
    zipCode: "",
    allMeals: Constants.DEFAULT_CHECKOUT_MEALS,
  };

  googleMap: any = React.createRef();
  formSection: any = React.createRef();
  listSection: any = React.createRef();
  pageRef: any = React.createRef();

  loadPlanOptions(store: any) {
    if (!store) {
      return;
    }

    let planOptions = store?.products?.mealPlans;

    if (!planOptions?.length) {
      return;
    }

    planOptions = _.filter(planOptions, (plan: any) => {
      return plan?.availableToNewClients;
    });

    let procurementTypes: any = [];

    let frequencyTypes: any = [];

    for (let i = 0; i < planOptions?.length; i++) {
      let plan = planOptions[i];
      const idx = i;

      if (!frequencyTypes.includes(plan?.frequency)) {
        frequencyTypes.push(plan?.frequency);
      }

      if (!_.findWhere(procurementTypes, { procurement: plan?.procurement })) {
        let procurement: any = {
          procurement: plan?.procurement,
        };

        switch (procurement.procurement) {
          case "pickup":
            procurement.name = "In-Store Pickup";
            procurement.displayOrder = 1;
            break;
          case "delivery":
            procurement.name = "Local Delivery";
            procurement.displayOrder = 2;
            break;
          case "shipping":
            procurement.name = "Shipping";
            procurement.displayOrder = 3;
            break;
          default:
            break;
        }

        procurementTypes.push(procurement);
      }
    }

    procurementTypes = _.sortBy(procurementTypes, "displayOrder");

    let activePlanType = null;
    let activeProcurement = null;

    if (procurementTypes?.length) {
      activeProcurement = procurementTypes[0];
    }

    frequencyTypes = frequencyTypes.sort((a: any, b: any) => {
      return a - b;
    });

    this.setState(
      {
        planOptions,
        procurementTypes,

        activeProcurement,
        frequencyTypes,
        activeFrequency: frequencyTypes?.includes("weekly")
          ? "weekly"
          : frequencyTypes[0],
      },
      () => {
        const planType = _.findWhere(this.state.planTypes, {
          _id: this.state.activePlanType,
        });

        let allMeals =
          this.state.store?.menus?.meals[planType?._id]?.active?.meals;

        this.setState({
          allMeals,
        });

        const plans = this.getPlansForCategory(
          planType?._id,
          this.state.activeProcurement,
          this.state.activeFrequency
        );

        this.trackProductListViewed(planType, plans);
      }
    );
  }

  /**
   * Fires a segment event for product list viewed
   *
   * @param planType
   * @param plans
   */
  async trackProductListViewed(planType: any, plans: any) {
    if (!planType || !plans?.length) {
      //console.log("HERE HERE", planType, plans);

      return;
    }

    SegmentUtils.track("Product List Viewed", {
      category: SegmentProductCategories.MEAL_PLANS,
      list_id: planType?._id + "_" + this.state.activeProcurement?.procurement,
      products: plans.map((plan: any, i: any) => {
        return {
          product_id: plan?._id,
          name: plan?.name,
          sku: plan?.code,
          category:
            planType?.name + " " + this.state.activeProcurement?.procurement,
          position: i + 1,
          price: this.getPrice(plan, false, true) / 100,
          brand: "Project LeanNation",
          quantity: plan?.quantity,
          procurement: plan?.procurement,
          frequency: plan?.frequency,
          store_id: this.state.store?.googleMapsPlaceID
            ? this.state.store?.googleMapsPlaceID
            : "",
          category1: planType?.name,
          category2: plan?.procurement,
          category3: plan?.frequency,
        };
      }),
    });
  }

  async handlePrelaunch(store: any) {
    if (!store || !DateUtils.storeIsPrelaunch(store)) {
      this.setState({
        prelaunch: false,
      });

      if (
        this.state.coupon?.defaultCoupon &&
        this.state.coupon?.defaultStoreID != store?._id
      ) {
        this.setState({
          coupon: null,
        });

        PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, null);

        StorageUtils.set("checkout_coupon", null);
      }

      return;
    }

    this.setState({
      prelaunch: true,
    });

    if (store?.operations?.defaultCoupon?.coupon) {
      let coupon = store?.operations?.defaultCoupon?.coupon;

      coupon.defaultCoupon = true;
      coupon.defaultStoreID = store?._id;

      SegmentUtils.track("Coupon Applied", {
        coupon_id: coupon?.code,
        coupon_name: coupon?.name,
        coupon_terms: coupon?.terms ? coupon?.terms : "",
        type: "discount",
      });

      this.setState({
        coupon,
      });

      PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, {
        coupon: coupon,
        forceShow: true,
      });

      StorageUtils.set("checkout_coupon", coupon);
    }
  }

  async loadStore() {
    const store = await StorageUtils.get("checkout_store");

    console.log("STORENAME", store?.name); //debug

    if (!this.state.lead || this.state.lead?.storeID != store?._id || !store) {
      this.setState({
        loadingStore: true,
      });
    }

    if (store) {
      this.setState({
        store,
      });

      if (DateUtils.storeIsComingSoon(store, true)) {
        this.setState({
          comingSoon: true,
          selectLocation: true,
        });

        return;
      } else {
        this.setState({
          selectLocation: false,
          comingSoon: false,
        });
      }

      this.handlePrelaunch(store);
    }

    // TODO: get from lead, fallback to store cache
    // if none, redirect to locations

    let storeID = store?._id;

    if (!storeID && this.state.lead?.storeID) {
      storeID = this.state.lead?.storeID;
    }

    //

    if (!storeID) {
      this.setState({
        selectLocation: true,
        allMeals: Constants.DEFAULT_CHECKOUT_MEALS,
      });

      return;
    }

    API.getStoreByID(storeID)
      .then(
        (data: any) => {
          const store = data?.data?.data?.store;

          let existingCoupon: any = StorageUtils.get("checkout_coupon");

          if (existingCoupon) {
            if (
              existingCoupon?.defaultCoupon &&
              existingCoupon?.defaultStoreID != store?._id
            ) {
              this.setState({
                coupon: null,
              });

              PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, null);
            }
          }

          if (store?.operations?.defaultCoupon?.coupon) {
            let coupon = store?.operations?.defaultCoupon?.coupon;

            coupon.defaultCoupon = true;
            coupon.defaultStoreID = store?._id;

            PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, {
              coupon: coupon,
              forceShow: true,
            });

            StorageUtils.set("checkout_coupon", coupon);
          }

          StorageUtils.set("checkout_store", store);

          this.setState({
            store,
            selectLocation: false,
          });

          this.loadPlanOptions(store);

          this.handlePrelaunch(store);
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          loadingStore: false,
        });
      });
  }

  async selectStore(store: any) {
    let lead: any = this.state.lead;

    this.setState({
      storeSubmitting: true,
    });

    //console.log("LEAD", lead);

    if (!lead) {
      lead = await StorageUtils.get("checkout_lead");
    }

    let existingCoupon: any = StorageUtils.get("checkout_coupon");

    if (existingCoupon) {
      if (
        existingCoupon?.defaultCoupon &&
        existingCoupon?.defaultStoreID != store?._id
      ) {
        this.setState({
          coupon: null,
        });

        StorageUtils.set("checkout_coupon", null);

        PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, null);
      }
    }

    if (store?.operations?.defaultCoupon?.coupon) {
      let coupon = store?.operations?.defaultCoupon?.coupon;

      coupon.defaultCoupon = true;
      coupon.defaultStoreID = store?._id;

      this.setState({
        coupon,
      });

      PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, {
        coupon: coupon,
        forceShow: true,
      });

      StorageUtils.set("checkout_coupon", coupon);
    }

    StorageUtils.set("checkout_store", store);

    this.setState({
      store: store,
      selectLocation: false,
    });

    const identity: SegmentIdentify = {
      email: lead?.email,
    };

    if (lead?.phone) {
      identity.phone = lead?.phone;
    }

    if (store) {
      identity.store_id = store?._id;
      identity.store_name = store?.name?.replace("PLN", "Project LeanNation");
    }

    if (lead?.name) {
      identity.first_name = StringUtils.getFirstName(lead?.name);
      identity.last_name = StringUtils.getLastName(lead?.name);
    }

    SegmentUtils.track("Location Assigned", {
      store_id: store?._id,
      store_name: store?.name?.replace("PLN", "Project LeanNation"),
    });

    SegmentUtils.identify(identity);

    if (DateUtils.storeIsComingSoon(store, true)) {
      // console.log("COMING SOON");

      this.setState({
        comingSoon: true,
        store,
        selectLocation: true,
      });

      if (lead) {
        lead.storeID = store?._id;

        if (this.state.zipCode) {
          lead.zipCode = this.state.zipCode;
        }

        API.updateLeadCart(lead, { step: "plans" }, true)
          .then(
            async (data: any) => {
              let l = data.data.data.lead;

              await StorageUtils.set("checkout_lead", l);
            },
            (e) => {
              console.error(e);
            }
          )
          .finally(() => {
            this.setState({
              selectingStore: false,
              storeSubmitting: false,
            });
          });
      }

      return;
    }

    this.loadPlanOptions(store);

    this.handlePrelaunch(store);

    //console.log("HERE");

    let existingStore = await StorageUtils.get("checkout_store");

    if (existingStore && existingStore?._id != store?._id) {
      console.log("RESET DATE");

      await StorageUtils.set("checkout_first_bill_date", "");
    }

    await StorageUtils.set("checkout_store", store);

    lead.storeID = store?._id;

    if (this.state.zipCode) {
      lead.zipCode = this.state.zipCode;
    }

    if (this.state.name) {
      lead.name = this.state.name;
    }

    PubSub.publish(Event.CHECKOUT.STORE_UPDATED, store);

    API.updateLeadCart(lead, { step: "plans" }, true)
      .then(
        async (data: any) => {
          let l = data.data.data.lead;

          await StorageUtils.set("checkout_lead", l);

          //PubSub.publish(Event.CHECKOUT.LEAD_RESTORED, l);

          console.log("LEAD LOADED");

          this.setState({
            selectLocation: false,
            selectingStore: false,
            loadingStore: false,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          selectLocation: false,
          storeSubmitting: false,
        });
      });
  }

  getStoresForZipCode(zipCode: string) {
    if (!this.state.zipCode) {
      this.setError("error", "Please enter a valid zip code to continue.");

      return;
    }

    if (
      parseInt(this.state.zipCode) < 501 ||
      parseInt(this.state.zipCode) > 99950
    ) {
      this.setError("error", "Please enter a valid zip code to continue.");

      return;
    }

    this.setState({
      submitting: true,
      storeOptions: [],
      noStoreFound: false,
      comingSoon: false,
    });

    API.getStoresForZip(zipCode)
      .then(
        (data: any) => {
          let stores = data?.data?.data?.stores?.length
            ? data?.data?.data?.stores
            : [];

          this.setState({
            storeOptions: stores,
          });

          if (stores?.length == 0) {
            this.setState({
              noStoreFound: true,
            });
          } else if (stores?.length == 1) {
            this.selectStore(stores[0]);
          }

          console.log(stores);
        },
        (e) => {
          // TODO: handle error with message here
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  _loadLead(lead: any) {
    if (!lead) {
      return;
    }

    if (lead?.zipCode) {
      this.setState({
        zipCode: lead?.zipCode,
      });
    }

    if (lead?.name) {
      this.setState({
        name: lead?.name,
      });
    }

    if (lead?.coupon) {
      this.setState({
        coupon: lead?.coupon,
        couponCode: lead?.coupon ? lead?.coupon?.code : "",
      });

      StorageUtils.set("checkout_coupon", lead?.coupon);

      PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, {
        coupon: lead?.coupon,
      });
    } else {
      StorageUtils.get("checkout_coupon").then((coupon: any) => {
        if (coupon) {
          this.setState({ coupon, couponCode: coupon ? coupon?.code : "" });
        } else {
          this.setState({ coupon: null, couponCode: "" });
        }
      });
    }

    this.setState(
      {
        lead,
      },
      () => {
        this.loadStore();
      }
    );
  }

  ionViewDidEnter() {
    console.log("LOADING PLANS");

    let urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("s") == "1") {
      this.setState({
        selectLocation: true,
      });
    }

    const windowSize = WindowUtils.getDimensions();

    if (windowSize !== null) {
      const mobile = windowSize?.width < 768;

      this.setState({
        isMobile: mobile,
      });
    }

    StorageUtils.get("checkout_lead").then((lead: any) => {
      if (lead) {
        console.log(lead);

        this._loadLead(lead);
      } else {
        this.props.history.push("/start");
      }
    });
  }

  componentDidMount() {
    PubSub.subscribe(Event.CHECKOUT.LEAD_RESTORED, (lead: any) => {
      this._loadLead(lead);
    });

    PubSub.subscribe(Event.WINDOW_RESIZE, (data: any) => {
      const mobile = data?.width < 768;

      if (mobile !== this.state.isMobile) {
        this.setState({
          isMobile: mobile,
        });
      }
    });

    PubSub.subscribe(Event.CHECKOUT.STORE_UPDATED, (store: any) => {
      this.setState({
        store,
      });

      this.handlePrelaunch(store);
    });
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (this.state.store?._id != prevState?.store?._id) {
      this.loadPlanOptions(this.state.store);
    }
  }

  getPrice(plan: any, byMeal: any = true, numeric = false) {
    if (!plan) {
      return null;
    }

    let cents = plan?.unitPrice + (plan?.deliveryFee ? plan?.deliveryFee : 0);

    if (byMeal) {
      cents = cents / plan?.quantity;
    }

    if (numeric) {
      return cents;
    }

    return StringUtils.centsToCurrency(cents);
  }

  getPromotionalPrice(plan: any, coupon: any, byMeal = true) {
    let price = NumberUtils.getCouponPromotionalPrice(plan, coupon);

    if (byMeal) {
      return price.mealPrice;
    }

    return price.planPrice;
  }

  getPlansForCategory(
    categoryID: string,
    procurementType: any,
    frequency: any
  ) {
    if (!categoryID || !this.state.planOptions?.length) {
      return [];
    }

    let planOptions = _.filter(this.state.planOptions, (plan: any) => {
      return (
        plan?.planType?._id == categoryID &&
        plan?.procurement == procurementType?.procurement &&
        plan?.frequency == frequency
      );
    });

    planOptions = _.sortBy(planOptions, "quantity");

    return planOptions;
  }

  getPlanType(categoryID: string) {
    if (!categoryID) {
      return;
    }

    let planType = _.findWhere(this.state.planTypes, { _id: categoryID });

    if (!planType) {
      return null;
    }

    planType = _.clone(planType);

    planType.name += " Meal Plans";

    return planType;
  }

  buildRandomBox(plan: any) {
    return new Promise((resolve: any) => {
      let meals = this.state.allMeals;

      // console.log(meals);

      meals = _.filter(meals, (meal) => {
        return !_.findWhere(meal?.categories, { name: "Lean Cheats" });
      });

      if (!meals?.length) {
        meals = this.state.allMeals;
      }

      function getRandomInt(min: any, max: any) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      let selected: any = [];

      for (let i = 0; i < plan.quantity; i++) {
        const randIdx = getRandomInt(0, meals.length - 1);

        let selectedMeal: any = _.clone(meals[randIdx]);

        let idx = _.findIndex(selected, { _id: selectedMeal._id });

        if (idx >= 0) {
          selected[idx].quantity++;
        } else {
          selectedMeal.quantity = 1;

          selected.push(selectedMeal);
        }
      }

      resolve(selected);
    });
  }

  async selectPlan(plan: any) {
    if (!plan) {
      return;
    }

    await StorageUtils.set("checkout_plan", plan);

    let meals = await this.buildRandomBox(plan);

    StorageUtils.set("checkout_meals", meals);

    let lead = this.state.lead;

    API.updateLeadCart(lead, {
      step: "checkout",
      planID: plan?._id?.toString(),
      meals,
    }).then(
      async (data: any) => {
        let l = data.data.data.lead;

        // console.log("LOADED", l);

        await StorageUtils.set("checkout_lead", l);

        //PubSub.publish(Event.CHECKOUT.LEAD_RESTORED, l);
      },
      (e) => {
        console.error(e);
      }
    );

    PubSub.publish(Event.CHECKOUT.PLAN_UPDATED, plan);

    /**
     * Segment track product clicked
     */
    const allPlans = this.getPlansForCategory(
      this.state.activePlanType,
      this.state.activeProcurement,
      this.state.activeFrequency
    );

    const idx = _.findIndex(allPlans, { _id: plan._id });

    SegmentUtils.track("Product Clicked", {
      brand: "Project LeanNation",
      category: SegmentProductCategories.MEAL_PLANS,
      coupon: "",
      frequency: plan?.frequency,
      procurement: plan?.procurement,
      name: plan?.name,
      position: idx + 1,
      price: this.getPrice(plan, false, true) / 100,
      quantity: 1,
      sku: plan?.code,
      product_id: plan?._id,
    });

    this.props.history.push("/start/checkout");
  }

  setError(key: any, msg: any) {
    if (!msg) {
      this.setState({
        [key]: "",
      });

      return;
    }

    this.setState({
      [key]: msg,
    });

    setTimeout(() => {
      this.setError(key, "");
    }, 5000);
  }

  addNameToLead(name: string) {
    let email = this.state.lead?.email;
    let params: any = {
      name,
      phone: this.state.lead?.phone,
    };

    params.eventSourceURL = window?.location?.href
      ? window?.location?.href
      : "";

    this.setState({
      nameSubmitting: true,
      nameError: "",
      nameSuccess: "",
    });

    API.createOrUpdateLead(email, "", "", params)
      .then(
        (data: any) => {
          this.setError(
            "nameSuccess",
            "Thanks! We'll keep you in the loop as we expand to your area."
          );
        },
        (e) => {
          this.setError(
            "nameError",
            "There was an error submitting your information, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          nameSubmitting: false,
        });
      });
  }

  getMarketingName(store: any) {
    if (!store) {
      return "";
    }

    if (store?.type != "retail") {
      return "Direct Shipping";
    }

    return StringUtils.getMarketingName(store);
  }

  render() {
    return (
      <IonPage>
        <NavbarV2
          showSupportOptions={false}
          onSupportChat={() => {}}
          onSupportCall={() => {}}
          onGoBack={() => {
            if (this.state.selectLocation) {
              return this.props.history.push("/start");
            }

            this.setState({
              selectLocation: true,
              allMeals: Constants.DEFAULT_CHECKOUT_MEALS,
            });
          }}
          activeStep={this.state.selectLocation ? 1.5 : 2}
          showBackButton={true}
          store={this.state.store}
        ></NavbarV2>
        <IonContent style={{ background: "var(--white)" }}>
          <div
            className="pb-lg-4"
            style={{
              background: "var(--white)",
            }}
          >
            <div className="pb-0 pb-lg-0">
              <div
                style={{
                  maxWidth: 900,
                  margin: "auto",
                  overflow: "hidden",
                }}
              >
                <IonGrid className="p-0">
                  <IonRow>
                    <IonCol
                      size-xs={12}
                      size-md={12}
                      className="pt-0 px-0 pb-0 left-col"
                      style={{
                        background: "var(--white)",
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    >
                      <div className="px-4 pt-4 max-w-[35rem] mx-auto">
                        <p
                          className={`text-gray-900 font-bold text-center ${
                            this.state.selectLocation
                              ? "text-[17px] leading-5"
                              : "text-base"
                          }`}
                        >
                          {this.state.selectLocation ? (
                            <>
                              Enter your zip code to view
                              <br className="d-sm-none" /> local plans &
                              pricing:
                            </>
                          ) : (
                            "Viewing plans from your nearest store:"
                          )}
                        </p>
                        {this.state.selectLocation ? (
                          <>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();

                                this.getStoresForZipCode(this.state.zipCode);
                              }}
                            >
                              <div className="mt-3 sm:mt-4">
                                <input
                                  placeholder="Zip Code"
                                  id="frequency"
                                  value={this.state.zipCode}
                                  onChange={(e) => {
                                    this.setState({
                                      zipCode: e.target.value
                                        .replace(/\D/g, "")
                                        .substring(0, 5),
                                    });
                                  }}
                                  className="col-span-2 bg-white text-capitalize border border-gray-100 text-gray-900 text-base rounded-lg outline-0 focus:ring-orange-500 focus:border-orange-500 block w-full p-2 h-[40px] placeholder-gray-400"
                                />
                              </div>
                              <button
                                disabled={this.state.submitting}
                                className={`mt-3 sm:mt-4 w-full font-bold rounded-lg ${
                                  this.state.submitting ||
                                  this.state.zipCode?.length <= 4
                                    ? "bg-orange-300"
                                    : "bg-orange-500"
                                } shadow-sm text-white outline-0 focus:ring-orange-500 focus:border-orange-500 p-2 h-[40px]`}
                              >
                                {this.state.submitting ? (
                                  <IonSpinner name="dots" color="light" />
                                ) : (
                                  "Find My Store"
                                )}
                              </button>
                              {this.state.error ? (
                                <p className="mt-2 text-red-500 text-sm text-center">
                                  {this.state.error}
                                </p>
                              ) : null}
                            </form>
                            {this.state.noStoreFound ? (
                              <>
                                <div className="mt-3">
                                  <p className="mt-0 mb-1 text-center">
                                    Unfortunately we don't have a store in your
                                    area.
                                  </p>
                                  <p className="mt-0 mb-3 text-center">
                                    Enter a different zip code to view other PLN
                                    stores or provide your name below and we'll
                                    notify you when we're coming to your area!
                                  </p>
                                  <div className="p-3 border rounded-lg border-gray-100">
                                    {this.state.nameSuccess ? (
                                      <div className="py-4 px-3 text-center text-gray-900">
                                        Thanks! We'll keep you in the loop as we
                                        expand to your area.
                                      </div>
                                    ) : (
                                      <form
                                        onSubmit={(e) => {
                                          e.preventDefault();

                                          this.addNameToLead(this.state.name);
                                        }}
                                      >
                                        <div className="">
                                          <p className="text-sm mb-1 font-medium text-gray-900">
                                            Your Name:
                                          </p>
                                          <input
                                            placeholder="Your Name"
                                            id="name"
                                            value={this.state.name}
                                            onChange={(e) => {
                                              this.setState({
                                                name: e.target.value,
                                              });
                                            }}
                                            className="col-span-2 bg-white text-capitalize border border-gray-100 text-gray-900 text-base rounded-lg outline-0 focus:ring-orange-500 focus:border-orange-500 block w-full p-2 h-[40px] placeholder-gray-400"
                                          />
                                        </div>
                                        <button
                                          disabled={this.state.nameSubmitting}
                                          className={`mt-3 sm:mt-4 w-full font-bold rounded-lg ${
                                            this.state.nameSubmitting ||
                                            !this.state.name?.length
                                              ? "bg-orange-300"
                                              : "bg-orange-500"
                                          } shadow-sm text-white outline-0 focus:ring-orange-500 focus:border-orange-500 p-2 h-[40px]`}
                                        >
                                          {this.state.nameSubmitting ? (
                                            <IonSpinner
                                              name="dots"
                                              color="white"
                                            />
                                          ) : (
                                            "Notify Me"
                                          )}
                                        </button>
                                        {this.state.nameError ? (
                                          <p className="mt-2 text-red-500 text-sm text-center">
                                            {this.state.nameError}
                                          </p>
                                        ) : null}
                                      </form>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : null}
                            {this.state.comingSoon ? (
                              <>
                                <div className="mt-3">
                                  <p className="mt-0 mb-1 text-lg font-bold text-center">
                                    🎉{" "}
                                    {this.state.store?.name?.replace(
                                      "Project LeanNation",
                                      "PLN"
                                    )}
                                    &nbsp;Coming Soon 🎉
                                  </p>
                                  <p className="mt-0 mb-1 text-center">
                                    Great news, a Project LeanNation is coming
                                    soon to your area soon!
                                  </p>
                                  <p className="mt-0 mb-3 text-center">
                                    Enter your name below and we'll send you
                                    opening information & exclusive offers!
                                  </p>
                                  <div className="p-3 border rounded-lg border-gray-100">
                                    {this.state.nameSuccess ? (
                                      <div className="py-4 px-3 text-center text-gray-900">
                                        Thanks! Keep an eye out for messages
                                        from us as we get closer to opening!
                                      </div>
                                    ) : (
                                      <form
                                        onSubmit={(e) => {
                                          e.preventDefault();

                                          this.addNameToLead(this.state.name);
                                        }}
                                      >
                                        <div className="">
                                          <p className="text-sm mb-1 font-medium text-gray-900">
                                            Your Name:
                                          </p>
                                          <input
                                            placeholder="Your Name"
                                            id="name"
                                            value={this.state.name}
                                            onChange={(e) => {
                                              this.setState({
                                                name: e.target.value,
                                              });
                                            }}
                                            className="col-span-2 bg-white text-capitalize border border-gray-100 text-gray-900 text-base rounded-lg outline-0 focus:ring-orange-500 focus:border-orange-500 block w-full p-2 h-[40px] placeholder-gray-400"
                                          />
                                        </div>
                                        <button
                                          disabled={this.state.nameSubmitting}
                                          className={`mt-3 sm:mt-4 w-full font-bold rounded-lg ${
                                            this.state.nameSubmitting ||
                                            !this.state.name?.length
                                              ? "bg-orange-300"
                                              : "bg-orange-500"
                                          } shadow-sm text-white outline-0 focus:ring-orange-500 focus:border-orange-500 p-2 h-[40px]`}
                                        >
                                          {this.state.nameSubmitting ? (
                                            <IonSpinner
                                              name="dots"
                                              color="white"
                                            />
                                          ) : (
                                            "Notify Me"
                                          )}
                                        </button>
                                        {this.state.nameError ? (
                                          <p className="mt-2 text-red-500 text-sm text-center">
                                            {this.state.nameError}
                                          </p>
                                        ) : null}
                                      </form>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : null}
                            {this.state.storeOptions?.length > 1 ? (
                              <>
                                <div className="mt-3">
                                  <p className="mt-0 mb-3 text-center">
                                    Select the store nearest to you:
                                  </p>
                                  {this.state.storeOptions?.map(
                                    (store: any) => (
                                      <div
                                        onClick={() => {
                                          this.selectStore(store);
                                        }}
                                        className={`p-3 cursor-pointer items-center flex gap-x-3 rounded-lg border border-gray-100 mb-3 ${
                                          this.state.storeSubmitting
                                            ? "bg-gray-50 text-gray-300"
                                            : "text-gray-900"
                                        }`}
                                        key={store?._id}
                                      >
                                        <div className="grow">
                                          <p className="text-base font-bold">
                                            {store?.name
                                              ?.replace(
                                                "Project LeanNation",
                                                "PLN"
                                              )
                                              ?.trim()}
                                          </p>
                                          <p className="text-sm">
                                            {
                                              store?.location?.address
                                                ?.formattedAddress
                                            }
                                          </p>
                                        </div>
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="h-8 w-8"
                                          >
                                            <path d="m9 18 6-6-6-6" />
                                          </svg>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <div className="mt-3 sm:mt-4 grid grid-cols-2 items-center gap-x-3 gap-y-4">
                              <div>
                                <div
                                  onClick={() => {
                                    this.setState({
                                      selectLocation: true,
                                      allMeals:
                                        Constants.DEFAULT_CHECKOUT_MEALS,
                                    });
                                  }}
                                  className="cursor-pointer px-2 py-[0.2rem] border rounded-lg border-gray-100transition-all rounded-lg w-full"
                                >
                                  <div className="flex flex-nowrap items-center">
                                    <div className="sm:pr-1">
                                      <svg
                                        width="20"
                                        height="21"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          y="0.5"
                                          width="20"
                                          height="20"
                                          rx="10"
                                          fill="#FB6340"
                                        />
                                        <path
                                          d="M13.0194 7.51936L5.61503 10.885L9.42938 11.1093L9.65376 14.9237L13.0194 7.51936Z"
                                          fill="white"
                                          stroke="white"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <div className="pl-2 pr-1 text-truncate grow">
                                      {this.state?.loadingStore ? (
                                        <IonSkeletonText
                                          style={{
                                            width: "100%",
                                            height: "24px",
                                          }}
                                          animated={!this.state.selectLocation}
                                        ></IonSkeletonText>
                                      ) : (
                                        <>
                                          <div
                                            style={{
                                              lineHeight: 1.2,
                                              minWidth: 0,
                                            }}
                                            className="text-base text-gray-900 font-bold sm:font-medium tracking-tight text-truncate"
                                          >
                                            {this.state.store?.name
                                              ?.replace(
                                                "Project LeanNation",
                                                ""
                                              )
                                              ?.trim()}
                                          </div>
                                          <div
                                            style={{ lineHeight: 1.1 }}
                                            className="text-xs text-truncate text-gray-900"
                                          >
                                            {this.state.store?.location?.address?.city?.trim()}
                                            ,{" "}
                                            {this.state.store?.location?.address?.state?.trim()}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        className="w-4 h-4"
                                      >
                                        <title>pencil</title>
                                        <path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state.loadingStore ? (
                                <>
                                  <IonSkeletonText
                                    style={{
                                      width: "100%",
                                      height: "38px",
                                    }}
                                    animated={!this.state.selectLocation}
                                    className="rounded-lg"
                                  ></IonSkeletonText>
                                </>
                              ) : (
                                <>
                                  <div className="grid grid-cols-2 gap-x-0 items-center rounded-lg border border-gray-100">
                                    <div
                                      style={{ lineHeight: 1.2 }}
                                      className={`${
                                        this.state.activeProcurement
                                          ?.procurement == "pickup"
                                          ? "bg-orange-500 text-white"
                                          : "bg-white text-gray-700"
                                      } py-[0.6rem] text-center px-2 transition-all rounded-lg text-base font-bold  cursor-pointer`}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            activeProcurement: _.findWhere(
                                              this.state.procurementTypes,
                                              {
                                                procurement:
                                                  this.state.activeProcurement
                                                    ?.procurement == "pickup"
                                                    ? "delivery"
                                                    : "pickup",
                                              }
                                            ),
                                          },
                                          () => {
                                            const allPlans =
                                              this.getPlansForCategory(
                                                this.state.activePlanType,
                                                this.state.activeProcurement,
                                                this.state.activeFrequency
                                              );

                                            const planType = _.findWhere(
                                              this.state.planTypes,
                                              {
                                                _id: this.state.activePlanType,
                                              }
                                            );

                                            this.trackProductListViewed(
                                              planType,
                                              allPlans
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      Pickup
                                    </div>
                                    <div
                                      style={{ lineHeight: 1.2 }}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            activeProcurement: _.findWhere(
                                              this.state.procurementTypes,
                                              {
                                                procurement:
                                                  this.state.activeProcurement
                                                    ?.procurement == "pickup"
                                                    ? "delivery"
                                                    : "pickup",
                                              }
                                            ),
                                          },
                                          () => {
                                            const allPlans =
                                              this.getPlansForCategory(
                                                this.state.activePlanType,
                                                this.state.activeProcurement,
                                                this.state.activeFrequency
                                              );

                                            const planType = _.findWhere(
                                              this.state.planTypes,
                                              {
                                                _id: this.state.activePlanType,
                                              }
                                            );

                                            this.trackProductListViewed(
                                              planType,
                                              allPlans
                                            );
                                          }
                                        );
                                      }}
                                      className={`${
                                        this.state.activeProcurement
                                          ?.procurement == "delivery"
                                          ? "bg-orange-500 text-white"
                                          : "bg-white text-gray-700"
                                      } py-[0.6rem] text-center px-2 transition-all rounded-lg text-base font-bold  cursor-pointer`}
                                    >
                                      Delivery
                                    </div>
                                  </div>
                                </>
                              )}
                              {this.state.frequencyTypes?.length > 1 ? (
                                <>
                                  <select
                                    id="frequency"
                                    value={this.state.activeFrequency}
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          activeFrequency: e.target.value,
                                        },
                                        () => {
                                          const allPlans =
                                            this.getPlansForCategory(
                                              this.state.activePlanType,
                                              this.state.activeProcurement,
                                              this.state.activeFrequency
                                            );

                                          const planType = _.findWhere(
                                            this.state.planTypes,
                                            {
                                              _id: this.state.activePlanType,
                                            }
                                          );

                                          this.trackProductListViewed(
                                            planType,
                                            allPlans
                                          );
                                        }
                                      );
                                    }}
                                    className="col-span-2 bg-white text-capitalize border border-gray-100 text-gray-900 text-base rounded-lg outline-0 focus:ring-orange-500 focus:border-orange-500 block w-full p-2 h-[40px] placeholder-gray-400"
                                  >
                                    <option disabled>
                                      Select An Order Frequency
                                    </option>
                                    {this.state.frequencyTypes?.map(
                                      (freq: any) => (
                                        <option value={freq} key={freq}>
                                          {this.state.activeProcurement
                                            ?.procurement == "pickup"
                                            ? "Pickup Your Order"
                                            : "Receive Your Order"}
                                          &nbsp;
                                          {freq == "biweekly"
                                            ? "Bi-Weekly"
                                            : freq}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </>
                              ) : null}
                            </div>
                          </>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            {/** Desktop View */}

            <div
              style={{ maxWidth: 850, margin: "auto" }}
              className={`${
                this.state.selectLocation &&
                (this.state.noStoreFound || this.state.comingSoon
                  ? "blur-3xl"
                  : "blur")
              } sm:block`}
            >
              <div
                className="px-4 text-center"
                ref={this.formSection}
                style={this.state.formSectionStyles}
              >
                {this.state.loadingStore ? (
                  <>
                    <div className="flex justify-center mt-3">
                      <IonSkeletonText
                        style={{
                          width: "100%",
                          maxWidth: 510,
                          height: 38,
                          borderRadius: 6,
                        }}
                        className="rounded-lg"
                      ></IonSkeletonText>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="grid mt-3 max-w-[510px] mx-auto grid-cols-2 gap-x-0 items-center rounded-lg border border-gray-100">
                      {this.state.planTypes?.map((planType: any) => (
                        <div
                          onClick={() => {
                            if (this.state.selectLocation) {
                              return;
                            }

                            this.setState({
                              activePlanType: planType?._id,
                            });

                            const p = _.findWhere(this.state.planTypes, {
                              _id: planType?._id,
                            });

                            const plans = this.getPlansForCategory(
                              planType?._id,
                              this.state.activeProcurement,
                              this.state.activeFrequency
                            );

                            let allMeals =
                              this.state.store?.menus?.meals[planType?._id]
                                ?.active?.meals;

                            this.setState({
                              allMeals,
                            });

                            this.trackProductListViewed(p, plans);
                          }}
                          style={{ lineHeight: 1.2 }}
                          className={`${
                            this.state.activePlanType == planType?._id
                              ? this.state.selectLocation
                                ? "bg-gray-100 text-gray-300 cursor-not-allowed"
                                : "bg-orange-500 text-white cursor-pointer"
                              : this.state.selectLocation
                              ? "bg-white text-gray-300 cursor-not-allowed"
                              : "bg-white text-gray-700 cursor-pointer"
                          } py-[0.6rem] text-center px-2 transition-all rounded-lg text-base font-bold`}
                        >
                          {planType?.name} Meal Plans
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div>
                <div>
                  {this.state.loadingStore ? (
                    <>
                      <div style={{}} className="mt-3 flex justify-center">
                        <IonSkeletonText
                          animated={!this.state.selectLocation}
                          style={{ width: "100%", maxWidth: 300, height: 20 }}
                        ></IonSkeletonText>
                      </div>
                      <div
                        className="mt-1 mb-3 mx-4 mx-sm-0 flex justify-center"
                        style={{}}
                      >
                        <IonSkeletonText
                          animated={!this.state.selectLocation}
                          style={{
                            width: "100%",
                            maxWidth: 500,
                            height: 55,
                          }}
                        ></IonSkeletonText>
                      </div>
                    </>
                  ) : (
                    <div className="mt-3 text-center sm:max-w-[620px] sm:mx-auto">
                      <div
                        style={{}}
                        className="text-lg font-bold sm:text-lg d-inline"
                      >
                        {
                          this.getPlanType(this.state.activePlanType)
                            ?.calorieMin
                        }
                        -
                        {
                          this.getPlanType(this.state.activePlanType)
                            ?.calorieMax
                        }{" "}
                        Calories / Meal
                      </div>
                      <p className="mt-1 text-base leading-[1.25rem] sm:leading-5 font-normal text-gray-900">
                        Choose a plan below to continue.
                      </p>
                      <p className="mt-1 mb-2 mb-lg-3 text-base leading-[1.25rem] sm:leading-5 font-normal text-gray-900 px-3">
                        Our plans include tasty, perfectly portioned meals to
                        help you hit your health goals!
                      </p>
                    </div>
                  )}
                </div>

                {this.state.loadingStore ? (
                  <>
                    <div className="px-[1.25rem] grid grid-cols-3 gap-x-3 pt-2">
                      <IonSkeletonText
                        style={{ width: "100%", height: 148 }}
                        className="rounded-lg"
                        animated={!this.state.selectLocation}
                      ></IonSkeletonText>
                      <IonSkeletonText
                        style={{ width: "100%", height: 148 }}
                        className="rounded-lg"
                        animated={!this.state.selectLocation}
                      ></IonSkeletonText>
                      <IonSkeletonText
                        style={{ width: "100%", height: 148 }}
                        className="rounded-lg"
                        animated={!this.state.selectLocation}
                      ></IonSkeletonText>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="px-[1.25rem] grid grid-cols-3 gap-x-3 pt-2">
                      {this.getPlansForCategory(
                        this.state.activePlanType,
                        this.state.activeProcurement,
                        this.state.activeFrequency
                      )?.map((plan: any, i: number) => (
                        <>
                          <div
                            key={i}
                            className={`relative rounded-lg ${
                              !this.state.selectLocation &&
                              "hover:bg-orange-50 group hover:border-orange-500"
                            } cursor-pointer  transition-all border-[1px] py-[1rem] pl-[0.5rem] pr-[0.5rem] ${
                              plan?.quantity == 12 &&
                              plan?.planType?.name
                                ?.toLowerCase()
                                ?.includes("lifestyle") &&
                              !this.state.selectLocation
                                ? "shadow hover:shadow-lg bg-orange-25 border-orange-500"
                                : "shadow-sm hover:shadow border-gray-100"
                            } ${this.state.selectLocation && "bg-gray-50"} ${
                              plan?.quantity == 12 &&
                              plan?.planType?.name
                                ?.toLowerCase()
                                ?.includes("lifestyle")
                                ? "mt-[1rem]"
                                : "mt-[1rem]"
                            }`}
                            onClick={() => {
                              if (this.state.selectLocation) {
                                return;
                              }

                              this.selectPlan(plan);
                            }}
                          >
                            {plan?.quantity == 12 &&
                            plan?.planType?.name
                              ?.toLowerCase()
                              ?.includes("lifestyle") &&
                            !this.state.selectLocation ? (
                              <>
                                <div
                                  style={{
                                    transform: "translateX(-50%)",
                                  }}
                                  className="flex items-center justify-center w-[80px] sm:w-[100px] absolute top-[-12px] left-[50%] bg-orange-500 p-1 rounded-lg"
                                >
                                  <div>
                                    <svg
                                      className="fill-white h-[12px] w-[12px] relative top-[-2px]"
                                      width="12"
                                      height="11"
                                      viewBox="0 0 12 11"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z"
                                        fill="inherit"
                                      />
                                    </svg>
                                  </div>
                                  <div
                                    style={{ lineHeight: 1.2 }}
                                    className="text-sm sm:text-base text-white  ml-[0.1rem] sm:ml-[0.3rem] font-medium leading-4 tracking-tight"
                                  >
                                    For You
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div className="text-center">
                              <p
                                style={{ lineHeight: 1 }}
                                className={`m-0 pt-2 text-2xl sm:text-3xl font-bold tracking-tight transition-all ${
                                  this.state.selectLocation
                                    ? "text-gray-300"
                                    : "text-gray-900"
                                }`}
                              >
                                {plan?.quantity}
                              </p>
                              <p
                                className={`m-0 font-bold text-xl tracking-tight ${
                                  this.state.selectLocation
                                    ? "text-gray-300"
                                    : "text-gray-900"
                                }`}
                              >
                                Meals
                              </p>

                              {this.state.selectLocation ? (
                                <>
                                  <p className="mt-1 mb-0 text-xs sm:text-sm text-gray-400">
                                    Enter zip code above to view pricing
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="mt-1 mb-0 font-weight-500 text-sm sm:text-base text-gray-900">
                                    {this.state.coupon ? (
                                      <>
                                        <span className="text-orange-700">
                                          {this.getPromotionalPrice(
                                            plan,
                                            this.state.coupon
                                          )}{" "}
                                          / meal
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span className="text-gray-700">
                                          {this.getPrice(plan)} / meal
                                        </span>
                                      </>
                                    )}
                                  </p>
                                  {this.state.coupon ? (
                                    <>
                                      <p
                                        style={{
                                          textDecoration: "line-through",
                                        }}
                                        className="mt-0 mb-0 font-weight-500 text-xs sm:text-sm text-gray-900"
                                      >
                                        <span className="text-gray-700">
                                          {this.getPrice(plan)} / meal
                                        </span>
                                      </p>
                                    </>
                                  ) : null}

                                  <p
                                    className={`text-xs sm:text-sm text-gray-700`}
                                  >
                                    <span>
                                      {" "}
                                      {this.getPromotionalPrice(
                                        plan,
                                        this.state.coupon,
                                        false
                                      )}{" "}
                                      total
                                    </span>
                                  </p>
                                </>
                              )}
                            </div>
                            <div className="group-hover:bg-orange-500 group-hover:text-white transition-all mt-2 mx-[0.25rem] sm:mx-auto flex items-center sm:max-w-[160px] justify-center bg-tranparent text-orange-500 p-1 sm:p-2 border-[1px] border-orange-500 rounded">
                              <div
                                style={{ lineHeight: 1.2 }}
                                className="text-sm sm:text-base font-medium leading-4 tracking-tight"
                              >
                                Select
                                <span className="hidden sm:inline">
                                  &nbsp;Plan
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div className="pt-[1rem] mt-[2rem] md:mt-10 border-t border-gray-100 md:pt-6 pb-2 pb-lg-0 px-4">
                <div className="text-center">
                  <p className="mt-2 md:text-lg text-gray-900">
                    You'll be able to choose your meal selection from our
                    current menu after you place your order 🎉
                  </p>
                </div>
              </div>
              <div className="mt-[1rem] md:mt-8 px-2">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4 px-[0.75rem] md:px-0">
                  {this.state.loadingStore ? (
                    <>
                      <IonSkeletonText
                        style={{ width: "100%", height: 148 }}
                        animated={!this.state.selectLocation}
                        className="rounded-lg"
                      ></IonSkeletonText>
                      <IonSkeletonText
                        style={{ width: "100%", height: 148 }}
                        animated={!this.state.selectLocation}
                        className="rounded-lg"
                      ></IonSkeletonText>
                      <IonSkeletonText
                        style={{ width: "100%", height: 148 }}
                        animated={!this.state.selectLocation}
                        className="rounded-lg"
                      ></IonSkeletonText>
                    </>
                  ) : (
                    <>
                      {this.state.allMeals?.map((meal: any, i: any) => (
                        <div key={i} className="shadow-sm">
                          <MealOptionCardNoSelection
                            position={i + 1}
                            modalID="webapp-plans-box"
                            meal={meal}
                            mobile={this.state.isMobile}
                          ></MealOptionCardNoSelection>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer bgWhite={true}></Footer>
          <MealDetailModal
            id="webapp-plans-box"
            presentingElement={this.pageRef}
          ></MealDetailModal>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(PlansV2);
