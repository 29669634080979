import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonModal,
  IonNote,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import API from "../../lib/API";
import StorageUtils from "../../lib/StorageUtils";
import PubSub from "../../lib/PubSub";
import Event from "../../lib/Event";

import StringUtils from "../../lib/StringUtils";
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";
import { Capacitor } from "@capacitor/core";
import SegmentUtils, {
  SegmentCheckoutFlowSignupStartedProps,
  SegmentIdentify,
} from "../../lib/SegmentUtils";
import LinkUtils from "../../lib/LinkUtils";
import { close } from "ionicons/icons";
import DateUtils from "../../lib/DateUtils";
import { isValidPhoneNumber } from "libphonenumber-js";

const ConversionForm: React.FC<{
  forceSelectStore?: boolean;
  leadSource?: string;
  mode?: string;
}> = ({ forceSelectStore = false, leadSource = "Website: Visit", mode }) => {
  const w: any = window;
  const [googleClient, setGoogleClient] = useState<any>(null);
  const history = useHistory();
  const [email, setEmail] = useState<any>("");
  const [phone, setPhone] = useState<any>("");
  const [phoneReadable, setPhoneReadable] = useState("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const marketingModalRef = useRef<HTMLIonModalElement>(null);
  const [invalidPhone, setInvalidPhone] = useState<boolean>(false);

  /**
   * Handles redirects from google & apple oauth
   *
   * @param payload
   * @returns
   */
  const handleOAuthRedirect = async (payload: any) => {
    if (!payload) {
      return;
    }

    const identity: SegmentIdentify = {
      email: payload?.email,
    };

    if (payload?.lead?.phone) {
      identity.phone = payload?.lead?.phone;
    }

    if (payload?.store) {
      identity.store_id = payload?.store?._id;
      identity.store_name = payload?.store?.name;
    }

    if (payload?.lead?.name) {
      identity.first_name = StringUtils.getFirstName(payload?.lead?.name);
      identity.last_name = StringUtils.getLastName(payload?.lead?.name);
    }

    if (payload?.lead?.userID) {
      identity.id = payload?.lead?.userID;
    }

    SegmentUtils.identify(identity);

    if (payload?.redirect == "active_member") {
      // TODO: redirect with sign in, save token

      return;
    }

    // 1) Set lead in storage
    await StorageUtils.set("checkout_lead", payload?.lead);

    // 2) Set email in storage
    await StorageUtils.set("checkout_email", payload?.email);

    if (payload?.lead?.cartState?.plan) {
      await StorageUtils.set("checkout_plan", payload?.lead?.cartState?.plan);
    }

    // Set a coupon if present
    if (payload?.lead?.coupon) {
      await StorageUtils.set("checkout_coupon", payload?.lead?.coupon);

      PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, {
        coupon: payload?.lead?.coupon,
      });
    }

    const props: SegmentCheckoutFlowSignupStartedProps = {
      first_session: payload?.lead?.cartState?.step ? false : true,
    };

    // Log segment here
    SegmentUtils.track("Signup Started", props);

    // Redirect to locations page if no location is set for the lead
    if (!payload?.lead?.storeID || forceSelectStore) {
      return history.push("/start/plans");
    }

    // 4) Set store data
    if (payload?.store) {
      await StorageUtils.set("checkout_store", payload?.store).then(() => {
        // TODO: fire off twilio segment event here

        if (payload?.lead?.cartState?.step) {
          const step = payload?.lead?.cartState?.step;

          switch (step) {
            case "plan":
              return history.push("/start/plans");

            case "menu":
              return history.push("/start/box");
            case "checkout":
              return history.push("/start/checkout");
          }
        }

        return history.push("/start/plans");
      });
    } else {
      return history.push("/start/plans");
    }
  };

  /**
   * Memo to load the google client for sign in purposes
   */
  /**
 *   const loadSignIn = useMemo(() => {
    if (!w.google || googleClient) {
      return;
    }

    try {
      let client: any = w.google.accounts.oauth2.initTokenClient({
        client_id:
          "508705100957-vi6b2kvikgdgab9te8pjo2rqiurmu5jj.apps.googleusercontent.com",
        scope:
          "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/user.phonenumbers.read",
        prompt: "consent",

        callback: (response: any) => {
          API.signInWithGoogleLeads(response)
            .then(
              (data: any) => {
                let payload = data?.data?.data;

                handleOAuthRedirect(payload);
              },
              (e: any) => {}
            )
            .finally(() => {
              setSubmitting(false);
            });
        },
        error_callback: (e: any) => {
          console.error(e);

          setSubmitting(false);

          // TODO: error handler here
        },
      });

      setGoogleClient(client);
    } catch (e) {}
  }, [w.google]);
 */

  /**
   * Handle sign in with Google attempts
   *
   * @returns
   */
  const signInWithGoogle = () => {
    if (!googleClient) {
      return;
    }

    setSubmitting(true);

    googleClient.requestAccessToken();
  };

  const signInWithApple = () => {
    let nonce = StringUtils.requestID();
    let clientId = Capacitor.isNativePlatform()
      ? "com.projectleannation.app"
      : "com.projectleannation.appweb";

    let options: any = {
      clientId,
      redirectURI: window.location.href,
      scopes: "email name",
      state: "",
      nonce,
    };

    if (!Capacitor.isNativePlatform()) {
      options.usePopup = true;
    }

    setSubmitting(true);

    // APPLE KEY ID:K9Y6LQ8QFJ

    SignInWithApple.authorize(options)
      .then((result: SignInWithAppleResponse) => {
        // Handle user information
        // Validate token with server and create new session

        let resp: any = result?.response;

        API.signInWithAppleLeads(resp, nonce, clientId).then(
          (data: any) => {
            let payload = data?.data?.data;

            handleOAuthRedirect(payload);
          },
          (e: any) => {
            console.error(e);
          }
        );
      })
      .catch((error) => {
        // Handle error
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  /**
   * Continues to sign up the lead via email & phone
   *
   * @param email
   * @param phone
   * @returns
   */
  const continueWithEmailPhone = async (email: any, phone: any) => {
    let formattedPhone: any = StringUtils.formatPhoneForServer(phone);

    if (!phone) {
      return;
    }

    if (
      !StringUtils.isValidPhone(formattedPhone) ||
      !StringUtils.isValidEmail(email)
    ) {
      return;
    }

    await StorageUtils.set("checkout_email", email);
    await StorageUtils.set("checkout_phone", formattedPhone?.toString());

    // 1) Make api call to create or update lead

    let params: any = {
      phone: formattedPhone,
      utmCampaign: null,
      utmMedium: null,
      utmSource: null,
      utmContent: null,
      utmTerms: null,
      promo: null,
    };

    let store = await StorageUtils.get("checkout_store");

    if (store) {
      params.storeID = store?._id;
    }

    params.eventSourceURL = window?.location?.href
      ? window?.location?.href
      : "";

    const source = leadSource ? leadSource : "";

    // TODO: fire form submitted event here

    API.createOrUpdateLead(email, "", source, params)
      .then(
        (data: any) => {
          // console.log(data?.data?.data);

          handleOAuthRedirect(data.data?.data);
        },
        (e) => {
          console.error(e);

          setSubmitting(false);

          // TODO: fire segment event here

          setError(
            e?.response?.message ?? "Unknown error occurred. Please try again"
          );
        }
      )
      .finally(() => {
        setSubmitting(false);
      });

    // 2) Log to posthog / segment

    // 3) Call oauth handler on return

    setSubmitting(true);
  };

  useEffect(() => {
    StorageUtils.get("checkout_email").then((email: any) => {
      if (email) {
        setEmail(email);
      }
    });

    StorageUtils.get("checkout_phone").then((phone: any) => {
      if (phone) {
        const res = StringUtils.formatPhoneNumberWhileTyping(phone?.toString());

        setPhone(res?.value);
        setPhoneReadable(res?.formatted);

        if (res?.value?.length >= 12) {
          let valid = isValidPhoneNumber(res?.value);

          if (!valid) {
            setInvalidPhone(true);
          } else {
            setInvalidPhone(false);
          }
        }
      }
    });
  }, []);

  return (
    <>
      <div className="text-center">
        <h1 className="display-1">Being Your Best Is Just A Meal Away</h1>
        <p className="lead mt-3 mt-md-2">
          Hitting your health goals has never been easier with our chef-prepared
          meals, tools, and coaching to help you be your best.
        </p>
      </div>

      {/**
       * <div>
        <IonGrid className="px-0">
          <IonRow>
            <IonCol size-xs={12} size-sm={6} className="pl-0 pr-0 pr-sm-2">
              <button
                className="mt-0 mt-md-3 signin-with-google-btn"
                style={{}}
                onClick={signInWithGoogle}
              >
                <div style={{ position: "relative", top: 1 }}>
                  <svg
                    style={{ height: 18, width: 18, marginRight: "0.5rem" }}
                    version="1.1"
                    viewBox="0 0 48 48"
                  >
                    <g>
                      <path
                        fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                      ></path>
                      <path
                        fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                      ></path>
                      <path
                        fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                      ></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </g>
                  </svg>
                </div>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "var(--dark)",
                    lineHeight: 1.2,
                  }}
                >
                  Continue with Google
                </div>
              </button>
            </IonCol>
            <IonCol size-xs={12} size-sm={6} className="pr-0 pl-0 pl-sm-2">
              <button
                className="mt-1 mt-sm-0 mt-md-3 signin-with-google-btn"
                style={{}}
                onClick={signInWithApple}
              >
                <div style={{ position: "relative", top: 0 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="814"
                    height="1000"
                    viewBox="0 0 814 1000"
                    style={{ height: 18, width: 15, marginRight: "0.6rem" }}
                  >
                    <path
                      fill="#000000"
                      d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"
                    />
                  </svg>
                </div>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "var(--dark)",
                    lineHeight: 1.2,
                  }}
                >
                  Continue with Apple
                </div>
              </button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <div
        className="mt-2 pb-1 text-medium font-weight-500 text-center"
        style={{ fontSize: 14 }}
      >
        or continue with
      </div>
       */}
      <form
        onSubmit={(e: any) => {
          e.preventDefault();

          continueWithEmailPhone(email, phone);
        }}
      >
        <div className="mt-4">
          <div>
            <div className="relative">
              <label
                htmlFor="name"
                className="absolute rounded-sm -top-[9px] left-2 inline-block bg-white px-1.5 text-[13px] font-medium text-gray-800 focus:text-orange-500"
              >
                Email
                <span className="text-red-600 ml-[2px]">*</span>
              </label>
              <input
                type="email"
                name="email"
                autoComplete="email"
                value={email}
                className="block w-full text-base sm:text-lg rounded outline-0 border border-gray-100 px-3 py-[0.7rem] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 transition-all focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:leading-6"
                placeholder="hello@projectleannation.com"
                onChange={(event) => {
                  setEmail(event.target.value?.toLowerCase());
                }}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="relative mx-auto">
              <label
                htmlFor="name"
                className="absolute rounded-sm -top-[9px] left-2 inline-block bg-white px-1.5 text-[13px] font-medium text-gray-800 focus:text-orange-500"
              >
                Phone
                <span className="text-red-600 ml-[2px]">*</span>
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                value={phoneReadable}
                required={true}
                autoComplete="tel"
                className="block w-full text-base sm:text-lg rounded outline-0 border border-gray-100 px-3 py-[0.7rem] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 transition-all focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:leading-6"
                placeholder="+1 (908) 867-5309"
                onChange={(event: any) => {
                  let result = StringUtils.formatPhoneNumberWhileTyping(
                    event?.target?.value
                  );

                  setPhone(result?.value);
                  setPhoneReadable(result?.formatted);

                  if (result?.value?.length >= 12) {
                    let valid = isValidPhoneNumber(result?.value);

                    if (!valid) {
                      setInvalidPhone(true);
                    } else {
                      setInvalidPhone(false);
                    }
                  }
                }}
              />
              {invalidPhone ? (
                <p className="text-red-500 text-xs mb-0 mt-1">
                  Please enter a valid phone number to continue.
                </p>
              ) : (
                <p className="text-red-500 text-xs mb-0 mt-1">&nbsp;</p>
              )}
            </div>
          </div>

          <IonButton
            type="submit"
            className="pln-button-web size-lg mt-4"
            mode="ios"
            expand={"block"}
            color="primary"
            size="large"
            disabled={
              !email ||
              !phone ||
              !StringUtils.isValidPhone(phone) ||
              !StringUtils.isValidEmail(email) ||
              invalidPhone ||
              submitting
            }
          >
            {submitting ? (
              <>
                <IonSpinner name="dots"></IonSpinner>
              </>
            ) : (
              <>View Plans + Pricing</>
            )}
          </IonButton>
          <button
            type="submit"
            style={{ position: "absolute", visibility: "hidden" }}
          >
            submit
          </button>
          <div
            className="mt-3 text-center"
            style={{
              color: "var(--dark)",
              fontSize: 12,
            }}
          >
            By clicking "View Plans + Pricing",{" "}
            {/*"Continue with Google", or
            "Continue with Apple",*/}{" "}
            I agree to our{" "}
            <a
              href="https://www.iubenda.com/terms-and-conditions/76912715"
              rel="noreferrer"
              onClick={(e: any) => {
                e.preventDefault();

                LinkUtils.openInNewTab(
                  "https://www.iubenda.com/terms-and-conditions/76912715"
                );
              }}
              style={{ color: "var(--dark)", textDecoration: "underline" }}
            >
              Terms
            </a>
            &nbsp;and{" "}
            <a
              href="https://www.iubenda.com/privacy-policy/76912715"
              rel="noreferrer"
              onClick={(e) => {
                e.preventDefault();

                LinkUtils.openInNewTab(
                  "https://www.iubenda.com/privacy-policy/76912715"
                );
              }}
              style={{ color: "var(--dark)", textDecoration: "underline" }}
            >
              Privacy Policy
            </a>
            .
          </div>
          <div
            className="text-center mt-2"
            style={{
              color: "var(--dark)",
              fontSize: 12,
            }}
          >
            By providing my phone number, I agree to receive marketing &
            promotional information at the number provided that may be sent from
            an automated system. Consent is not required for purchase. Message &
            data rates may apply. Message frequency varies. Reply HELP for help
            or STOP to opt-out. See{" "}
            <a
              href="https://projectleannation.com/marketing-policy"
              target={"_blank"}
              rel="noreferrer"
              style={{ color: "var(--dark)", textDecoration: "underline" }}
            >
              Marketing Communication Policy
            </a>
          </div>
        </div>
      </form>

      <IonModal ref={marketingModalRef} mode="ios">
        <IonHeader>
          <IonToolbar
            className="box-modal-toolbar-web"
            style={{ borderBottom: "none" }}
          >
            <IonTitle>Marketing Policy</IonTitle>
            <IonButtons slot="end">
              <IonButton
                color="dark"
                onClick={() => {
                  marketingModalRef?.current?.dismiss();
                }}
              >
                <IonIcon slot="icon-only" icon={close}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div>
            <div className="p-4">
              By accepting Project LeanNation Franchising Inc.'s Marketing
              Policy, you assert the following: I agree to receive recurring
              telephone calls and/or SMS or MMS text messages or emails for
              marketing purposes at the phone number and/or email address
              provided, including but not limited to calls or texts sent using
              an automatic telephone dialing system or an artificial or
              prerecorded voice. I am authorized to consent to receive text
              messages sent to the phone number provided. I understand that I
              may revoke consent at any time. I understand that consent is not a
              condition of purchase. Message and data rates may apply. View our{" "}
              <a
                href="https://www.iubenda.com/terms-and-conditions/76912715"
                rel="noreferrer"
                onClick={(e: any) => {
                  e.preventDefault();

                  LinkUtils.openInNewTab(
                    "https://www.iubenda.com/terms-and-conditions/76912715"
                  );
                }}
              >
                Terms of Service
              </a>
              &nbsp;for details.
            </div>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ConversionForm;
